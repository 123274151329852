import React from "react";
import video1 from "../Assets/urbanz-fullflow.mp4";

const Video = () => {
  return (
    <div className="border border-black w-5/6 xl:w-[700px] m-auto ">
      <video src={video1} controls="controls"></video>
    </div>
  );
};

export default Video;
