import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import Video from "./Video";

const workVariant = {
  hidden: {},
  visible: {
    transition: {
      // duration: 1,
      when: "beforeChildren",
      staggerChildren: 0.4,
    },
  },
  exit: {},
};

const childVariants = {
  hidden: {
    y: "50vh",
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

const videoVariants = {
  hidden: {
    opacity: 0,
    y: "50vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

const Works = () => {
  const contentRef = useRef();
  const isContentInView = useInView(contentRef);
  const controls = useAnimation();

  useEffect(() => {
    if (isContentInView) {
      controls.start("visible");
    }
  }, [controls, isContentInView]);

  return (
    <section id="work" className="h-screen md:py-10 flex lg:flex-row flex-col items-center justify-center gap-y-16">
      <motion.div
        ref={contentRef}
        className="flex flex-col gap-2 px-2 w-5/6 lg:w-1/2 md:px-10"
        variants={workVariant}
        initial="hidden"
        animate={controls}
        exit="exit"
      >
        <motion.h1
          className="xl:text-2xl font-bold text-gradient"
          variants={childVariants}
        >
          Urbanz E-Commerce Website
        </motion.h1>
        <motion.h2
          className="xl:text-xl font-bold underline "
          variants={childVariants}
        >
          Description:
        </motion.h2>
        <motion.p variants={childVariants}>
          Urbanz is a one stop shop for all your fashion and lifestyle needs.
          Being an e-commerce store for fashion and lifestyle products, Urbanz
          aims at providing a hassle free and enjoyable shopping experience to
          shoppers with the widest range of cool trendy dress collections on its
          portal.
        </motion.p>
        <motion.h2
          className="xl:text-xl font-bold underline "
          variants={childVariants}
        >
          Languages:
        </motion.h2>
        <motion.ul variants={childVariants}>
          <li>
            Front End :
            <span className="lang">
              React Javascript Library, Redux-Toolkit, Tailwind Css
            </span>
          </li>
          <li>
            Back End : <span className="lang"> Javacript, Express JS</span>
          </li>
          <li>
            Database :
            <span className="lang">
              Mongo DB and Mongoose for DB Connection
            </span>
          </li>
        </motion.ul>
      </motion.div>
      <motion.div
        variants={videoVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className='w-full'
      >
        <Video />
      </motion.div>
    </section>
  );
};

export default Works;
