import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Works from "./components/Works";
import Contact from "./components/Contact";
import Favicon from "react-favicon";
import favicon from "./Assets/favicon.png"
function App() {
  // const location = useLocation();
  // const {hash}=location;
  return (
    
    <main className="font-rubik bg-black text-white">
      <Favicon url={favicon} />
      {/* <Navbar /> */}
      <Header/>
        <Home />
        <About />
        <Works />
        <Contact />
    </main>
  );
}

export default App;
//bg-gradient-to-tr from-black to-indigo-950
