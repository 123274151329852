import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Autoplay } from "swiper/modules";
import { IoLogoCss3 } from "react-icons/io";
import { IoLogoHtml5 } from "react-icons/io";
import { IoLogoJavascript } from "react-icons/io5";
import { IoLogoReact } from "react-icons/io5";
import { IoLogoNodejs } from "react-icons/io5";
import { BiLogoMongodb } from "react-icons/bi";
import { SiTailwindcss } from "react-icons/si";
import { SiRedux } from "react-icons/si";

const SkillsSwiper = () => {
  return (
    <div>
      <Swiper
        // slides-per-view="auto"
        loop={true}
        space-between="10"
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={5}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          modifier: 1,
        }}
        // pagination={true}
        modules={[EffectCoverflow,  Autoplay]}
        className="w-full py-[50px] bg-transparent"
      >
        <SwiperSlide className="bg-transparent">
          <div className="img-box">
            <IoLogoHtml5 className="text-red-600 w-full h-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img-box">
            <IoLogoCss3 className="text-blue-600 w-full h-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img-box">
            <IoLogoJavascript className="text-yellow-400 w-full h-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img-box">
            <IoLogoReact className="text-sky-400 w-full h-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img-box">
            <IoLogoNodejs className="text-green-400 w-full h-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img-box">
            <BiLogoMongodb className="text-green-900 w-full h-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img-box">
            <SiTailwindcss className="text-sky-400 w-full h-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img-box">
            <SiRedux className="text-violet-500 w-full h-full" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SkillsSwiper;
