import React, { useEffect, useRef } from "react";
import code from "../Assets/code.jpg";
import { motion } from "framer-motion";
import VanillaTilt from "vanilla-tilt";
import { HashLink } from "react-router-hash-link";
import { MdLocationPin } from "react-icons/md";
import resume from "../Assets/Vinitha_CV.pdf";

const parentVariant = {
  visible: {
    transition: {
      staggerChildren: 0.4,
    },
  },
};
const textVariant = {
  hidden: {
    opacity: 0,
    y: "50vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
    y: "-50vh",
    transition: {
      duration: 0.7,
    },
  },
};

const imageVariant = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    scale: 0,
    opacity: 0,
    transition: {
      duration: 0.7,
    },
  },
};

const buttonVariants = {
  tap: {
    scale: 0.85,
    rotate: "2.5deg",
  },
};

const Home = () => {
  const cardTilt = useRef();

  useEffect(() => {
    const options = {
      speed: 1000,
      max: 10,
    };

    VanillaTilt.init(cardTilt.current, options);
  });

  return (
    <main id="home" className="flex flex-col lg:flex-row w-screen h-full text-white md:py-10 no-scrollbar overflow-x-hidden">
      <motion.div
        className="text-center lg:text-left lg:w-1/2 grid place-content-center lg:px-10 px-20"
        variants={parentVariant}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          variants={textVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <h1 className="text-2xl font-bold mb-2">
            Hey, I'm <span className=" text-3xl xl:text-8xl text-gradient">Vinitha.</span>
          </h1>
        </motion.div>
        <motion.p
          variants={textVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="mb-3"
        >
          I'm motivated and self-directed
          <span className="font-bold xl:text-2xl text-gradient px-1">
            web developer
          </span>
          well-versed in designing, developing and implementing interactive
          websites and web applications.
        </motion.p>
        <motion.div className="flex flex-col gap-2" variants={textVariant}>
          <p className="font-bold mb-1 text-gradient">
            Educational Qualification:
          </p>
          <p>
            <span className="font-semibold">Bachelor of Engineering</span> |
            Computer Science and Technology
          </p>
          <p className="flex gap-2 ">
            <MdLocationPin className="scale-150" />
            Prince Shri Venkateshwara Padmavathy Engineering College, Chennai,
            India.
          </p>
        </motion.div>
        <motion.div
          className="grid place-content-center"
          variants={textVariant}
        >
          <HashLink to="#work">
            <motion.button
              className="btn my-10"
              variants={buttonVariants}
              whileHover="tap"
              transition={{ duration: 0.1 }}
            >
              View My Work
            </motion.button>
          </HashLink>
        </motion.div>
      </motion.div>
      <div className="lg:w-1/2 flex justify-center items-center ">
        <motion.div
          className="rounded-2xl w-2/3 xl:h-5/6 bg-[rgba(255,255,255,0.1)] shadow-2xl backdrop-blur-xl flex flex-col gap-16 justify-center items-center px-5 py-10 xl:py-28"
          ref={cardTilt}
          variants={imageVariant}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <motion.img
            src={code}
            alt="main pic"
            variants={imageVariant}
            whileHover="hover"
            className="w-[200px] h-[200px] lg:w-[250px] lg:h-[250px] rounded-full"
          ></motion.img>
          <div className="flex justify-around gap-x-16">
            <div className="relative">
              <HashLink to="#contact">
                <motion.div className="btn px-5 py-5">
                  <span className="absolute -top-1 -right-1 flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-r from-violet-800 to-pink-500 px-2 py-2 opacity-100"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-violet-200"></span>
                  </span>
                  <p className="text-xs xl:text-base"> Contact Me</p>
                </motion.div>
              </HashLink>
            </div>
            <a href={resume} download="VinithaResume">  
              <motion.button
                className="btn px-5 py-5 text-xs xl:text-base"
                whileHover={{
                  scale: 1.1,
                  transition: {
                    duration: 0.3,
                  },
                }}
              >
                My Resume
              </motion.button>
            </a>
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default Home;
