import React, { useEffect, useRef } from "react";
import {
  motion,
  useAnimation,
  useInView,
} from "framer-motion";
import SkillsSwiper from "./SkillsSwiper";

const parentVariants = {
  hidden: { opacity: 0, y: "50vh" },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.4,
    },
  },
};

const childVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const About = () => {
  const contentRef = useRef();
  const isContentInView = useInView(contentRef);
  const controls = useAnimation();

  useEffect(() => {
    if (isContentInView) {
      controls.start("visible");
    }
  }, [controls,isContentInView]);

  return (
    <section
      id="about"
      className="my-20 md:my-5 relative w-screen h-screen flex lg:flex-row flex-col justify-center items-center gap-y-32 no-scrollbar overflow-x-hidden"
    >
      <motion.div
        className="w-5/6 lg:w-1/2 md:ml-12"
        initial={{
          y: "30vh",
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration:1,
        }}
      >
        <SkillsSwiper />
      </motion.div>
      <motion.div
        className="w-2/4 flex flex-col gap-y-2 text-center"
        ref={contentRef}
        variants={parentVariants}
        initial="hidden"
        animate={controls}
        exit="exit"
      >
        <h1 className="text-xl md:text-3xl font-bold text-center mb-3 text-gradient">
          Coding Skill Set
        </h1>
        <motion.p variants={childVariants}>Html</motion.p>
        <motion.p variants={childVariants}>Css</motion.p>
        <motion.p variants={childVariants}>Javascript</motion.p>
        <motion.p variants={childVariants}>React</motion.p>
        <motion.p variants={childVariants}>Redux</motion.p>
        <motion.p variants={childVariants}>Framer motion</motion.p>
        <motion.p variants={childVariants}>Tailwind Css</motion.p>
        <motion.p variants={childVariants}>Express Js</motion.p>
        <motion.p variants={childVariants}>Mongo DB</motion.p>
      </motion.div>
    </section>
  );
};

export default About;
