import React, { useState } from "react";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
const textVariants = {
  // visible: { rotateZ: -90 },
  hover: {
    scale: 1.2,
    transition: {
      duration: 1,
      type: "spring",
      stiffness: 500,
    },
  },
};

const navItems = [
  { id: 1, link: "home", text: "Home" },
  { id: 2, link: "about", text: "Skillset" },
  { id: 3, link: "work", text: "Projects" },
  { id: 4, link: "contact", text: "Contact" },
];

const Header = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <div className="w-full h-12 sticky top-0 z-10 bg-gray-950 text-white">
      <ul className="hidden md:flex w-full h-full justify-around items-center">
        {navItems.map((item) => (
          <HashLink to={`#${item.link}`}>
            <motion.li
              key={item.id}
              variants={textVariants}
              whileHover="hover"
              animate="visible"
              className="hover:bg-gradient-to-r from-violet-500 from-10% to-pink-500 to-90% hover:text-transparent hover:bg-clip-text hover:font-bold"
            >
              {item.text}
            </motion.li>
          </HashLink>
        ))}
      </ul>
      <div
        className="flex w-full h-full md:hidden items-center justify-start sticky"
        onClick={handleNav}
      >
        {!nav &&(
          <AiOutlineMenu className="ml-6" />
        )}
      </div>
      <ul className={nav?"bg-slate-950 w-[60%] fixed top-0 left-0 h-screen flex flex-col space-y-20 ease-in-out duration-500 p-4":"flex flex-col space-y-20 p-4 fixed top-0 bottom-0 left-[-100%] ease-in-out duration-500"}>
        {nav && <AiOutlineClose className="absolute top-5 right-5 hover:scale-110 duration-200" onClick={handleNav}/>}
        {
          navItems.map((item)=>(
            <HashLink to={`#${item.link}`}>
            <motion.li
            onClick={handleNav}
              key={item.id}
              variants={textVariants}
              whileHover="hover"
              animate="visible"
              className="hover:bg-gradient-to-r from-violet-500 from-10% to-pink-500 to-90% hover:text-transparent hover:bg-clip-text hover:font-bold m-auto"
            >
              {item.text}
            </motion.li>
          </HashLink>
          ))
        }
      </ul>
    </div>
  );
};

export default Header;
